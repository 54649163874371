body {
  
  background-image: radial-gradient(circle at bottom center, rgba(0,0,0,.05), #689);
  background-color: #689 !important;
  color: #FFF !important;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto' !important;
}

body, p, a, .list, .item, .header, .container {
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto' !important;
  text-shadow: 1px 1px 8px rgba(0,0,0,0.1) !important;
}

.welterweight {
  font-weight: 600;
}

.crossedOut {
  opacity: 0.44;
}